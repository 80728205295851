const validation = {
  data() {
    return {
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
    }
  },
  methods: {
    requiredValidator(field) {
      return [
        v => !!v || `${field} is required`,
      ];
    },
    handleErrors(errors) {
      if(!errors) {
        return;
      }
      const errorList = [];
      errors.forEach(error => {
        errorList.push(error.message);
      });

      return errorList;
    }
  }
}

export default validation;
